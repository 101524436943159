/**
 * Componente de cambio de contraseña: Muestra el formulario de cambio de contraseña
 */

import { Box, Button, Description, Heading, Input, Label, Stack, StackRow } from "codekit";

import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Pwdrules from "../../components/Pwdrules/Pwdrules";

import { useRestorePasswordMutation } from "../../libs/redux/slices/forgotSlice/forgotApiSlice";
import { getPasswordStrength, setPasswordStrength } from "../../libs/redux/slices/passwordSlice/passwordSlice";
import {
  getUser as getUserForgot,
  showSuccessMessage as showSuccessMessageForgot,
  reset,
} from "../../libs/redux/slices/forgotSlice/forgotSlice";
import {
  getUser as getUserAuth,
  getPasswordFormMessage,
  showSuccessMessage as showSuccessMessageAuth,
  signOut,
} from "../../libs/redux/slices/authSlice/authSlice";
import { useLogoutMutation } from "../../libs/redux/slices/authSlice/authApiSlice";

const Changpwd = ({ origin, isSmallScreen }) => {

  const isLoginRequest = origin === "LOGIN-PAGE";
  const isForgotPasswordRequest = origin === "FORGOT-PAGE";

  const USRIDXXX = useSelector(isLoginRequest ? getUserAuth : getUserForgot);

  const passwordStrength = useSelector(getPasswordStrength);
  const passwordFormMessage = useSelector(getPasswordFormMessage);
  const [logout] = useLogoutMutation();

  const { isPasswordStrong } = passwordStrength;

  let title = "";
  let paragraph = "";

  if (isLoginRequest) {
    title = passwordFormMessage || "Cambiar contraseña";
    paragraph =
      "Es el momento de cambiar tu contraseña. Recuerda escoger una contraseña fuerte y no guardarlo en sitios inseguros.";
  }

  if (isForgotPasswordRequest) {
    title = "Cambiar contraseña";
    paragraph = `Recuerda crear una contraseña fuerte y no guardarla en sitios inseguros.`;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues: { password: "", passwordConfirm: "" } });

  const [restorePassword, { isLoading }] = useRestorePasswordMutation();

  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    const { password } = formData;

    try {
      await restorePassword({ USRIDXXX, password }).unwrap();

      if (isLoginRequest) {
        dispatch(signOut());
        await logout();
        dispatch(showSuccessMessageAuth(true));
      } else {
        dispatch(reset());
        dispatch(showSuccessMessageForgot(true));
      }
    } catch (error) {}
  };

  const password = useWatch({ name: "password", control });
  const passwordConfirm = useWatch({ name: "passwordConfirm", control });

  useEffect(() => {
    dispatch(setPasswordStrength(password));
  }, [dispatch, password]);

  const arePasswordEqual = password === passwordConfirm;
  const isFormValid = arePasswordEqual && isPasswordStrong;

  return (
    <>
      <Box textAlign="center" mb={8}>
        <Stack gap={8}>
          <Heading variant="heading-6">{title}</Heading>
          <Description variant="description-2">{paragraph}</Description>
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={8}>
          <StackRow>
            <Label htmlFor="password">Contraseña</Label>
            <Input
              id="password"
              type="password"
              disabled={isLoading}
              placeholder="Contraseña"
              {...register("password", { required: true })}
              isInvalid={errors.password}
              style={{ textTransform: "none" }}
            />
          </StackRow>
          <StackRow>
            <Label htmlFor="passwordConfirm">Repetir contraseña</Label>
            <Input
              id="passwordConfirm"
              type="password"
              disabled={isLoading}
              placeholder="Repetir Contraseña"
              {...register("passwordConfirm", { required: true })}
              isInvalid={errors.passwordConfirm}
              style={{ textTransform: "none" }}
            />
          </StackRow>

          {isSmallScreen && (
            <StackRow>
              <Pwdrules />
            </StackRow>
          )}
          <Button disabled={!isFormValid} type="submit" variant="primary-bold" isLoading={isLoading} isFull>
            Validar
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default Changpwd;
