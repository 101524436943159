import { useSelector } from "react-redux";
import { useGetDataGraphGroupMutation } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useEffect, useState } from "react";

export function useDataGraph({ MODIDXXX, PROIDXXX }) {
  const today = new Date();
  const twoMonthsAgo = new Date(today);
  twoMonthsAgo.setMonth(today.getMonth() - 2);
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  }

  const [filtersJson, setFiltersJson] = useState({
    filtersJson: JSON.stringify([
      {
        "TARCYYYY.REGFECXX": {BETWEEN: [formatDate(twoMonthsAgo), formatDate(today)]}
      }
    ]),
  });
  const [dataGraph, setDataGraph] = useState();
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const [getDataGraph] = useGetDataGraphGroupMutation();

  const handleResetFilterJson = () => {
    setFiltersJson({
      filtersJson: JSON.stringify([
        {
          "TARCYYYY.REGFECXX": {BETWEEN: [formatDate(twoMonthsAgo), formatDate(today)]}
        }
      ]),
    });
  };

  const handleSetFilterJson = (newFilters) => {
    setFiltersJson(newFilters);
  };

  useEffect(() => {
    const handleGetData = async () => {
      const { data } = await getDataGraph({ filters: filtersJson, USRIDXXX, CARIDXXX });
      if (data) {
        setDataGraph(data);
      }
    };
    handleGetData();
  }, [CARIDXXX, USRIDXXX, filtersJson, getDataGraph]);

  return { dataGraph, handleSetFilterJson, handleResetFilterJson };
}
