import { Taskgraf } from "./Graphics/Taskgraf";
import { Carxteam } from "./Graphics/Carxteam"; // Asegúrate de importar Carxteam correctamente

/**
 * Componente para seleccionar la grafica a pintar
 */
export function Graphsel({
  graphName,
  graphComponent,
  MODIDXXX,
  PROIDXXX,
  graphKeyName,
  filtersFieldsGraph
}) {
  const properties = {
    graphName,
    MODIDXXX,
    PROIDXXX,
    graphKeyName,
    filtersFieldsGraph,
  };

  const types = {
    Taskgraf: <Taskgraf {...properties} />,
    Carxteam: <Carxteam {...properties} />,
  };

  return <>{types[graphComponent]}</>;
}
