import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bugraphs } from "../components/Builders/Bugrafx/Bugraphs";
import { getDataUser, setTemporalUsers } from "../libs/redux/slices/authSlice/authSlice";
import { useGetGraphsQuery } from "../libs/redux/slices/Graficsx/graficsxApiSlice";
import { useGetUserTemporalQuery, useGetDashboardQuery } from "../libs/redux/slices/authSlice/authApiSlice";
import { TableLoader, BoxStatus } from "codekit";

function DashboardHome() {
  const dispatch = useDispatch();
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: graphs } = useGetGraphsQuery({ USRIDXXX, CARIDXXX });
  const { data: temporalUsers, isSuccess } = useGetUserTemporalQuery({ USRIDXXX });
  const { data: dataGraphics, isSuccess: isSuccessGraphics } = useGetDashboardQuery();

  //Hook para el seteo de los usuarios reemplazo
  useEffect(() => {
    if (isSuccess) {
      const { data = [] } = temporalUsers;
      dispatch(setTemporalUsers(data));
    }
  }, [isSuccess, temporalUsers, dispatch]);

  if (!isSuccessGraphics || !graphs) {
    return <BoxStatus variant="loading" text="Cargando información..." />;
  }
  const hasBugraphs = dataGraphics.data.some(
    (item) => item.GRACOMXX === "TASKGRAF" || item.GRACOMXX === "CARXTEAM"
  );
  const otherComponents = dataGraphics.data.filter(
    (item) => item.GRACOMXX !== "TASKGRAF" && item.GRACOMXX !== "CARXTEAM"
  );

  return (
    <>
      {hasBugraphs && <Bugraphs graphs={graphs.data} />}
      {otherComponents.map((CIMS0036, index) => {
        const CurrentGraphic = lazy(() =>
          import(`../components/${CIMS0036.GRACOMXX}`).catch((error) => {
            console.error(error);
            return { default: () => null };
          })
        );

        return (
          <Suspense fallback={<TableLoader />} key={index}>
            <CurrentGraphic />
          </Suspense>
        );
      })}
    </>
  );
}

export default DashboardHome;
