import { useMemo} from "react";
import { useSelector } from "react-redux";
import { useGetStatusFilterQuery } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useSelect } from "../useSelect";

export function useStatusFilter({ MODIDXXX, PROIDXXX }) {
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: Status } = useGetStatusFilterQuery({
    CARIDXXX,
    USRIDXXX,
  });

  const { getDataForSelect } = useSelect();
  const newStatus = useMemo(() => {
    if (!Status) return [];
    return getDataForSelect(Status.data, "ESTAIDXX", "ESTADESC");
  }, [Status, getDataForSelect]);
  return [newStatus];
}
