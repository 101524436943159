import { apiSlice } from "../../apiSlice";

const headers = { Modulecima: "Modimpxx" };

export const causapayApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProviders: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Causapay/getProvider`,
        method: "GET",
        params: { filter: parameters.filter },
        headers,
      }),
    }),
    getInvoiceProviders: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Causapay/getInvoiceProvider`,
        method: "GET",
        params: parameters,
        headers,
      }),
    }),
    createPagoCausacion: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Causapay`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["Create"],
    }),
    getClientById: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Causapay/getClientById`,
        method: "GET",
        params: { filter: parameters.filter },
        headers,
      }),
    }),
  }),
});

export const {
  useGetProvidersMutation,
  useGetInvoiceProvidersMutation,
  useCreatePagoCausacionMutation,
  useGetClientByIdMutation,
} = causapayApiSlice;
