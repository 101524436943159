import { useEffect, useMemo, useState } from "react";
import { Box, GraphicContainer, Stack, StackRow, SimpleBarChart } from "codekit";
import { useActionsGraphSelectFilters } from "../../../../utils/hooks/graphs/useActionsGraphSelectFilters";
import { useActionsGraphRangetFilters } from "../../../../utils/hooks/graphs/useActionsGraphRangetFilters";
import { useDataGraph } from "../../../../utils/hooks/graphs/useDataGraphGroup";
import { useStatusFilter } from "../../../../utils/hooks/graphs/useStatusFilter";
import { useFilterGraph } from "../../../../utils/hooks/graphs/useFilterGraphGroup";
import { useRedirectGraph } from "../../../../utils/hooks/useRedirectGraph";

export function Carxteam({
  graphName,
  MODIDXXX,
  PROIDXXX,
  graphKeyName,
  filtersFieldsGraph,
}) {
  const dataColorsCode = [
    { colorBarCurrent: "warning-500", colorBarPrevious: "secondary-600" },
  ];

  const [dataFilterOne] = useStatusFilter({ MODIDXXX, PROIDXXX });

  const isStatusFilterLoaded = useMemo(() => dataFilterOne && dataFilterOne.length > 0, [dataFilterOne]);
  const getStatusName = (id) => {
    const status = dataFilterOne.find((filter) => filter.value === id);
    return status ? status.text : "Desconocido";
  };

  const { dataGraph, handleSetFilterJson, handleResetFilterJson } = useDataGraph({
    MODIDXXX,
    PROIDXXX,
    enabled: isStatusFilterLoaded,
  });

  const { handleSetFilter, handleResetPesistFilter} = useFilterGraph();
  const { handleRedirectWithRouter } = useRedirectGraph({ MODIDXXX, PROIDXXX });

  const [valueFilterOne, handleFilterOne, clsFilterOne] = useActionsGraphSelectFilters();
  const [valueFilterDate, handleFilterRange, clsFilterRange, convertRangeDate] =
    useActionsGraphRangetFilters();

  const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(102);
  const [selectedPreviousStatus, setSelectedPreviousStatus] = useState(100);

  const barData = useMemo(() => {
    if (!isStatusFilterLoaded || !dataGraph?.data) return [];

    return dataGraph.data.map((item) => {
      let currentCount = 0;
      let previousCount = 0;

      item.details.forEach((detail) => {
        if (detail.statid === selectedCurrentStatus) {
          currentCount += detail.count;
        }
        if (detail.statid === selectedPreviousStatus) {
          previousCount += detail.count;
        }
      });

      return {
        name: item.USRNOMXX || "SIN NOMBRE",
        currentDate: `${getStatusName(selectedCurrentStatus)}`,
        numCurrenDate: currentCount,
        previousDate: `${getStatusName(selectedPreviousStatus)}`,
        numPreviousDate: previousCount,
        respID: item.TARUSRRE,
      };
    });
  }, [dataGraph, isStatusFilterLoaded, selectedCurrentStatus, selectedPreviousStatus]);

  const calculateTicks = (data) => {
    const allValues = data.flatMap((item) => [
      item.numCurrenDate,
      item.numPreviousDate,
    ]);
    const maxValue = Math.max(...allValues, 40);
    const step = Math.ceil(maxValue / 5);
    const ticks = Array.from({ length: 6 }, (_, i) => i * step);
    return { domain: [0, maxValue], ticks };
  };

  const ticksInfo = useMemo(() => calculateTicks(barData), [barData]);

  const handleClearFilters = () => {
    clsFilterOne();
    clsFilterRange();
    setSelectedCurrentStatus(102);
    setSelectedPreviousStatus(100);
    clsFilterRange();
    handleResetFilterJson();
    handleResetPesistFilter();
  };

  const handleBarClick = (context, isCurrent) => {
    const states = [selectedCurrentStatus, selectedPreviousStatus];
    const filters = handleSetFilter("filterTwo", context.data.respID, states);
    handleSetFilterJson(filters);
    handleRedirectWithRouter();
  };

  const handleVewMore = (event) => {
    handleResetPesistFilter();
    handleRedirectWithRouter();
  };

  const handleFilterRagRefact = (context) => {
    handleFilterRange(context);
    const filters = handleSetFilter("filterDate", convertRangeDate(context.option));
    handleSetFilterJson(filters);
  };

  if (!isStatusFilterLoaded) {
    return null;
  }

  return (
    <Box spacing={4}>
      <GraphicContainer
        containerName={graphName ?? "Gráfica de Barras - Carxteam"}
        graphicKeyName={graphKeyName ?? "carxteam-graph"}
        filterOneOptions={dataFilterOne}
        filterTwoOptions={dataFilterOne}
        onChangeValueFilteroOne={(context) =>
          setSelectedCurrentStatus(context.option.value)
        }
        onChangeValueFilteroTwo={(context) =>
          setSelectedPreviousStatus(context.option.value)
        }
        onChangeValueFilterDate={handleFilterRagRefact}
        valueFilterOne={selectedCurrentStatus}
        valueFilterTwo={selectedPreviousStatus}
        valueFilterDate={valueFilterDate}
        clearFilters={handleClearFilters}
        onClickVerMas={handleVewMore}
      >
        <Stack gap={8}>
          <StackRow>
            {barData && barData.length > 0 && (
              <SimpleBarChart
                data={barData}
                chartColors={dataColorsCode}
                domainXAxis={ticksInfo.domain}
                ticksXAxis={ticksInfo.ticks}
                image={false}
                onClickCurrentBar={(context) => handleBarClick(context, true)}
                onClickPreviousBar={(context) => handleBarClick(context, false)}
              />
            )}
          </StackRow>
        </Stack>
      </GraphicContainer>
    </Box>
  );
}
