import { createSlice } from "@reduxjs/toolkit";

const dosptefaSlice = createSlice({
  name: "dosptefa",
  initialState: {
    MDTCVLRS: {},
    checkedRows: {},
  },
  reducers: {
    setMDTCVLRS: (state, action) => {
      const { value, index, CONCOBID } = action.payload;
      state.MDTCVLRS = { ...state.MDTCVLRS, [CONCOBID]: { ...state.MDTCVLRS[CONCOBID], [index]: value } };
    },
    setMassiveMDTCVLRS: (state, action) => {
      const { value, DOS, CONCOBID } = action.payload;
      let dataDo = {};
      DOS.forEach((DOCNROXX) => {
        dataDo[DOCNROXX] = value;
      });
      state.MDTCVLRS = { ...state.MDTCVLRS, [CONCOBID]: { ...state.MDTCVLRS[CONCOBID], ...dataDo } };
    },
    setCheckedRows: (state, action) => {
      const { values, CONCOBID } = action.payload;
      state.checkedRows = {...state.checkedRows, [CONCOBID]: values}
    },
  },
});
export const { setMDTCVLRS, setCheckedRows, setMassiveMDTCVLRS } = dosptefaSlice.actions;
export default dosptefaSlice.reducer;

export const getMDTCVLRS = (state) => {
  return state.dosptefa.MDTCVLRS;
};

export const getCheckedRows = (state) => {
  return state.dosptefa.checkedRows;
};
