import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FieldError,
  Flexbox,
  Heading,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Stack,
  StackRow,
  Textarea,
  BoxStatus,
  Divider,
  shootToast,
} from "codekit";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useGetAutocompleteWithParamsMutation } from "../../../../libs/redux/slices/autocompSlice/autocompApiSlice";
import {
  useGetInvoiceProvidersMutation,
  useCreatePagoCausacionMutation,
} from "../../../../libs/redux/slices/Modimpxx/Causapay/causapayApiSlice";
import { useGetCurrentQuotaByClientMutation } from "../../../../libs/redux/slices/Financix/cupodispApiSlice";
import { convertCurrencyNumber } from "../utils/function";
import Solicupo from "../Solicupo";
import Anticipo from "../Anticipo";

import { getActualDate } from "../utils/function";
import Facturas from "./Facturas";

const Causapay = ({ setIsCauPay }) => {
  const [results, setResults] = useState([]);
  const [provider, setProvider] = useState(null);
  const [pagoProvider, setPagoProvider] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [facturas, setFacturas] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [getCurrentQuotaByClientId] = useGetCurrentQuotaByClientMutation();
  const [availableQuota, setAvailableQuota] = useState(0);
  const [valorFaltante, setValorFaltante] = useState(0);
  const [isSolicupoVisible, setIsSolicupoVisible] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isAnticipo, setIsAnticipo] = useState(false);
  const [fromPago, setFromPago] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const [getAutocompleteWithParams] = useGetAutocompleteWithParamsMutation();
  const [getInvoiceProviders] = useGetInvoiceProvidersMutation();
  const [savePagoCausacion, { isLoading }] = useCreatePagoCausacionMutation();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      CLIIDXXX: "",
      PROIDXXX: "",
    },
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const CLIIDXXX = useWatch({ name: "CLIIDXXX", control });

  const formsPago = [
    { text: "PSE", value: "PSE" },
    { text: "TRANSFERENCIA", value: "TRANSFERENCIA" },
  ];

  // Función para manejar el cambio de proveedor seleccionado
  const handleChangeSelectProvider = (context, isEmpty, camp) => {
    switch (camp) {
      case "PROIDXXX":
        if (!isEmpty) {
          setProvider(context.item.label);
          setValue("PROIDXXX", context.item.id);
          getInvoiceProviders({ filters: { PROIDXXX: context.item.id, CLIIDXXX: CLIIDXXX.split(" - ")[0] } })
            .unwrap()
            .then((response) => {
              setInvoices(response);
              setFacturas(true);
            })
            .catch((err) => {
              console.log("Error en getInvoiceProviders:", err);
              setFacturas(false);
            });
        } else {
          setProvider(null);
          setValue("PROIDXXX", "");
          setFacturas(false);
          setInvoices(null);
          setValorFaltante(0);
          setTotalPayment(0);
        }
        break;
      case "PROID2XX":
        if (!isEmpty) {
          setPagoProvider(context.item.label);
          setValue("PROID2XX", context.item.id);
        } else {
          setPagoProvider(null);
          setValue("PROID2XX", "");
        }
        break;
      default:
        break;
    }
  };

  // Función para realizar la búsqueda de proveedores
  const handleSearch = async (value, cb, endpoint) => {
    try {
      await getAutocompleteWithParams({
        filter: value,
        endpoint,
        MODURLXX: "Modimpxx",
        PRODURLX: "Causapay",
      })
        .unwrap()
        .then((response) => {
          setResults(response.data);
        });
    } catch (err) {
      console.log("Error en handleSearch:", err);
    } finally {
      cb();
    }
  };

  // Función para abrir Solicupo
  const openAnticipoModal = () => {
    setIsAnticipo(true);
  };

  // Función para abrir Solicitar Anticipo
  const openSolicupoModal = () => {
    setIsSolicupoVisible(true);
  };

  // Función para actualizar el valor total del pago
  const handleSelectedRowsChange = (rows) => {
    let total = 0;
    rows.forEach((row) => {
      let vlrfacxx = row.VLRFACXX.replace(/\./g, "").replace(",", ".");
      const parsedNumber = parseFloat(vlrfacxx);
      if (!isNaN(parsedNumber)) {
        total += parsedNumber;
      }
    });
    setSelectedRows(rows);
    setTotalPayment(total);
  };

  // Efecto para actualizar el valor faltante cuando cambia el total de pago o el cupo disponible
  useEffect(() => {
    const calculatedValorFaltante = totalPayment - availableQuota;
    setValorFaltante(calculatedValorFaltante < 0 ? 0 : calculatedValorFaltante);
  }, [availableQuota, totalPayment]);

  // Función para manejar el envío del formulario
  const onSubmit = async (formData) => {
    if (selectedRows.length === 0) {
      shootToast({
        variant: "error",
        text: "Debe Seleccionar al menos una Factura para la solicitud de Pago de Causación",
        autoHide: false,
        onClose: () => {
          console.log("Toast error closed");
        },
      });
      return;
    }

    const dataSend = {
      ...formData,
      CLIIDXXX: formData.CLIIDXXX.split(" - ")[0],
      VLRTOTAL: totalPayment,
      FACTUXXX: selectedRows.map((row) => ({
        FACIDXXX: row.FACIDXXX,
        CNSCOFEV: row.CNSCOFEV,
        VLRFACXX: row.VLRFACXX,
        IDCOMXXX: row.CNSCOMXX.split("-")[0].trim(),
        COMPREXX: row.CNSCOMXX.split("-")[1].trim(),
        CNSCOMXX: row.CNSCOMXX.split("-")[2].trim(),
      })),
    };

    await savePagoCausacion(dataSend)
      .unwrap()
      .then(async (response) => {
        setIsCauPay(false);
      })
      .catch()
      .finally(() => {});
  };

  const hasError = Object.keys(errors).length;
  const isFormValid = !hasError;

  //Funcion para consultar el cupo disponible del cliente
  const handleChangeClient = async (context) => {
    getCurrentQuotaByClientId({ CLIIDXXX: context.item.id })
      .unwrap()
      .then((response) => {
        setAvailableQuota(response.data.CUPODISP);
        setSelectedProvider({
          CLIIDXXX: context.item.id,
          DOCNROXX: "",
          CLINOMXX: context.item.label,
        });
      })
      .catch((err) => {
        console.log("Error en getCurrentQuotaByClientId:", err);
        setAvailableQuota(0);
      });
  };

  return (
    <Modal size="xl" isOpen>
      <ModalHeader>
        <Flexbox justifyContent="between" alignItems="center">
          <Heading variant="heading-6" color="accent-600">
            Solicitud Pago Causaciones
          </Heading>
          <Button variant="secondary" icon="close" onlyIcon size="m" onClick={() => setIsCauPay(false)}></Button>
        </Flexbox>
      </ModalHeader>
      <FormProvider {...methods}>
        <form id="causationPayment" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Box spacingX={2} spacingTop={2} spacingBottom={4} rounded="md" bg="white">
              <Stack columnsVariant="form-2" gap={4}>
                <StackRow>
                  <Box rounded="md" spacing={2}>
                    <Label>Fecha de solicitud</Label>
                    <Input {...register("FECSOLIX")} type="date" defaultValue={getActualDate()} readOnly />
                  </Box>
                </StackRow>
                <StackRow>
                  <Box rounded="md" spacing={2}>
                    <Label>Cliente</Label>
                    <Controller
                      control={control}
                      name="CLIIDXXX"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                          ref={ref}
                          placeholder="Buscar"
                          textLoading="Buscando..."
                          textNoResults="No se encontraron registros"
                          minLengthToSearch={2}
                          selected={value}
                          searchResults={results}
                          onChangeSearch={(value, cb) => handleSearch(value, cb, "getClientById")}
                          onItemClick={(context) => {
                            onChange(context.item.label);
                            handleChangeClient(context);
                          }}
                          onResetSelected={(context) => {
                            onChange("");
                            handleChangeSelectProvider(context, true, "PROIDXXX");
                          }}
                          isInvalid={errors.CLIIDXXX}
                        />
                      )}
                    />
                    {errors.CLIIDXXX && <FieldError>El campo Cliente no puede estar vacío</FieldError>}
                  </Box>
                </StackRow>
                <StackRow>
                  <Box rounded="md" spacing={2}>
                    <Label>Id o Nombre del Proveedor</Label>
                    <Autocomplete
                      {...register("PROIDXXX", { required: true })}
                      placeholder="Id o Nombre del Importador"
                      textLoading="Buscando..."
                      textNoResults="No se encontraron registros"
                      minLengthToSearch={2}
                      selected={provider}
                      searchResults={results}
                      onChangeSearch={(value, cb) => handleSearch(value, cb, "getProvider")}
                      onItemClick={(context) => {
                        handleChangeSelectProvider(context, "", "PROIDXXX");
                        clearErrors(`PROIDXXX`);
                      }}
                      onResetSelected={(context) => handleChangeSelectProvider(context, true, "PROIDXXX")}
                      isInvalid={errors.PROIDXXX}
                      readOnly={CLIIDXXX === ""}
                    />
                    {errors.PROIDXXX && (
                      <FieldError>El campo Nit o Nombre del Proveedor no puede estar vacío</FieldError>
                    )}
                  </Box>
                </StackRow>
                <StackRow>
                  <Box rounded="md" spacing={2}>
                    <Label>Id o Nombre del Beneficiario</Label>
                    <Autocomplete
                      {...register("PROID2XX")}
                      placeholder="Id o Nombre del Beneficiario"
                      textLoading="Buscando..."
                      textNoResults="No se encontraron registros"
                      minLengthToSearch={2}
                      selected={pagoProvider}
                      searchResults={results}
                      onChangeSearch={(value, cb) => handleSearch(value, cb, "getProvider")}
                      onItemClick={(context) => {
                        handleChangeSelectProvider(context, "", "PROID2XX");
                        clearErrors(`PROID2XX`);
                      }}
                      onResetSelected={(context) => handleChangeSelectProvider(context, true, "PROID2XX")}
                      readOnly={CLIIDXXX === ""}
                    />
                  </Box>
                </StackRow>
              </Stack>
            </Box>
            {provider ? (
              <Box spacing={1} rounded="xl" border={2} borderColor="neutral-300">
                {facturas ? (
                  <Facturas invoices={invoices} onSelectedRowsChange={handleSelectedRowsChange} />
                ) : (
                  <BoxStatus variant="loading" text="Cargando información..." rounded="xl" />
                )}
              </Box>
            ) : null}

            {provider && facturas && (
              <Box spacing={2}>
                <Stack columnsVariant="form-1" gap={3}>
                  <StackRow md={3}>
                    <Box>
                      <Label>Valor Total del Pago</Label>
                      <Input
                        type="text"
                        readOnly
                        style={{ textAlign: "right" }}
                        value={typeof totalPayment === "number" ? convertCurrencyNumber(totalPayment) : ""}
                      />
                    </Box>
                  </StackRow>
                </Stack>
              </Box>
            )}
            {CLIIDXXX !== "" && (
              <>
                <Box spacing={2}>
                  <Stack columnsVariant="form-1" gap={3}>
                    <StackRow md={3}>
                      <Box rounded="md" spacing={3} bg="success-200">
                        <Label color="neutral-600">
                          Disponible{" "}
                          <p style={{ textAlign: "right" }}>$ {`${convertCurrencyNumber(availableQuota)}`}</p>
                        </Label>
                      </Box>
                    </StackRow>
                  </Stack>
                </Box>
                <Box spacing={2}>
                  <Box
                    mb={5}
                    pb={0}
                    pt={0}
                    bg={valorFaltante > 0 || availableQuota <= 0 ? "error-400" : "success-600"}
                    rounded="md"
                    spacing={3}
                  >
                    <Stack gap={4} columns={valorFaltante > 0 || availableQuota <= 0 ? 2 : 1}>
                      <StackRow>
                        <Box mb={5}>
                          <Button
                            variant={valorFaltante > 0 || availableQuota <= 0 ? "error-400" : "success-600"}
                            color="white"
                            style={{ color: "white" }}
                            size="m"
                            type="button"
                            icon={valorFaltante > 0 || availableQuota <= 0 ? "close" : "circle-check"}
                            onClick={() => console.log("")}
                          >
                            {availableQuota <= 0 && valorFaltante > 0
                              ? "La operación no cuenta con Cupo disponible"
                              : availableQuota === 0 && valorFaltante === 0
                              ? "Cupo disponible de operación"
                              : availableQuota > 0 && valorFaltante > 0
                              ? "La operación no cuenta con cupo suficiente"
                              : "La operación cuenta con cupo suficiente"}
                          </Button>
                        </Box>
                      </StackRow>
                      {(valorFaltante > 0 || availableQuota <= 0) && (
                        <StackRow>
                          <Stack columns={2}>
                            <StackRow>
                              <Button
                                variant="secondary"
                                color="red"
                                style={{ color: "red" }}
                                size="m"
                                type="button"
                                onClick={() => openAnticipoModal()}
                              >
                                Solicitar anticipo
                              </Button>
                            </StackRow>
                            <StackRow>
                              <Button
                                variant="secondary"
                                color="red"
                                style={{ color: "red" }}
                                size="m"
                                type="button"
                                onClick={() => openSolicupoModal()}
                              >
                                Solicitar cupo adicional
                              </Button>
                            </StackRow>
                          </Stack>
                        </StackRow>
                      )}
                    </Stack>
                    <Stack gap={4} columns={valorFaltante > 0 || availableQuota <= 0 ? 3 : 2}>
                      <StackRow>
                        <Box mb={5} pb={0} pt={0} borderColor="light-100" border={1} rounded="md" spacing={3}>
                          <Stack columns={1}>
                            <StackRow>
                              <Label
                                p={1}
                                weight="medium"
                                color="white"
                                style={{ position: "absolute", float: "left" }}
                              >
                                Cupo disponible
                              </Label>
                              <Label alignment="left" color="white" style={{ position: "absolute", float: "right" }}>
                                $ {convertCurrencyNumber(availableQuota)}
                              </Label>
                            </StackRow>
                          </Stack>
                        </Box>
                      </StackRow>
                      <StackRow>
                        <Box mb={5} pb={0} pt={0} borderColor="light-100" border={1} rounded="md" spacing={3}>
                          <Stack columns={1}>
                            <StackRow>
                              <Label
                                p={1}
                                weight="medium"
                                color="white"
                                style={{ position: "absolute", float: "left" }}
                              >
                                Valor transaccional
                              </Label>
                              <Label alignment="left" color="white" style={{ position: "absolute", float: "right" }}>
                                $ {convertCurrencyNumber(totalPayment)}
                              </Label>
                            </StackRow>
                          </Stack>
                        </Box>
                      </StackRow>
                      {valorFaltante > 0 && (
                        <StackRow>
                          <Box mb={5} pb={0} pt={0} borderColor="light-100" border={1} rounded="md" spacing={3}>
                            <Stack columns={1}>
                              <StackRow>
                                <Label
                                  p={1}
                                  weight="medium"
                                  color="white"
                                  style={{ position: "absolute", float: "left" }}
                                >
                                  Valor faltante
                                </Label>
                                <Label alignment="left" color="white" style={{ position: "absolute", float: "right" }}>
                                  $ {convertCurrencyNumber(valorFaltante)}
                                </Label>
                              </StackRow>
                            </Stack>
                          </Box>
                        </StackRow>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </>
            )}
            <Box spacing={1}>
              <Divider text="Forma de Pago" color="accent-600" />
            </Box>
            <Box spacing={1}>
              <Stack columnsVariant="form-2" gap={3}>
                <Box>
                  <Label>Forma de pago</Label>
                  <Select
                    options={formsPago}
                    {...register("FORMPAGX", { required: true })}
                    value={fromPago}
                    onChangeValue={(context) => {
                      setFromPago(context.option.value);
                      setValue("FORMPAGX", context.option.value);
                      clearErrors(`FORMPAGX`);
                    }}
                  />
                  {errors.FORMPAGX && <FieldError>El campo Forma de pago no puede estar vacío</FieldError>}
                </Box>
              </Stack>

              <Box>
                <br />
                <Label>Observaciones</Label>
                <p>
                  En este espacio se debe incluir cuenta bancaria del proveedor o link de pago y/o usuarios y contraseña
                  si aplica. De lo contrario N/A
                </p>
                <Box bg="neutral-200" border={1} borderColor="neutral-300" spacing={3} rounded="md">
                  <Textarea {...register("OBSANTIX")} variant="white" />
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flexbox justifyContent="between" alignItems="center" gap={4}>
              <Button type="button" variant="secondary" size="2l" minWidth={200} onClick={() => setIsCauPay(false)}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary-bold"
                size="2l"
                minWidth={200}
                disabled={valorFaltante !== 0 || availableQuota <= 0 || !isFormValid}
                isLoading={isLoading}
              >
                Guardar
              </Button>
            </Flexbox>
          </ModalFooter>
        </form>
      </FormProvider>
      {/* Renderizado condicional de Solicupo */}
      {isSolicupoVisible && <Solicupo setIsSolicupo={setIsSolicupoVisible} doInfo={selectedProvider} />}
      {/* Renderizado condicional de Anticipo */}
      {isAnticipo && <Anticipo setIsAnticipo={setIsAnticipo} data={selectedProvider} />}
    </Modal>
  );
};

export default Causapay;
